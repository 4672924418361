import React from "react";
import BlurBackground from "./blurBackground";
import Logo from "./../../static/images/eco-ad-signet-weiss.svg";
import Close from "./../../static/images/close.svg";

const Sidebar = ({active, onCloseClick, lists}) => {
    return(
        <>
        { active &&
            <BlurBackground
                onClick={onCloseClick}
            />
        }
        <div className={
            (active)
            ?  "fixed bg-dark w-80 md:w-90 h-full top-0 right-0 z-30 transform-gpu translate-x-0 transition-transform ease-in duration-300"
            : "fixed bg-dark w-80 md:w-90 h-full top-0 right-0 z-30 transform-gpu translate-x-96 transition-transform ease-in duration-300"
            }>
            <div className="h-full py-5 px-10 m-auto flex flex-wrap justify-start items-center content-between">
                <div className="w-full flex justify-between items-start">
                    <div>
                        <img className="w-10 h-10 mt-1" src={Logo} alt="ecoAD Logo inverted"></img>
                    </div>
                    <button className="text-white cursor-pointer pt-2" onClick={onCloseClick}>
                        <img className="w-7 h-7" src={Close} alt="close Sidebar"></img>
                    </button>
                </div>
                <div className="w-full pl-10">
                        { lists.map((list, index) => {
                            return(
                                <div className="my-10" key={index}>
                                    <h5 className="font-bold text-gray-500 mb-5 text-lg">{list.header}</h5>
                                    <ul className="pl-3">
                                        { list.items.map((item, index) => {
                                            // Links sollten im neuen Tab geöffnet werden, bekommen daher das attr. target="blank"
                                            if(list.header === "Links"){
                                                return(
                                                    <li className="text-white mb-1 text-lg hover:text-ecoad-green" key={index}>
                                                        <a target="_blank" rel="noreferrer" href={item.link}>{item.text}</a>
                                                    </li>
                                                )
                                            }
                                            else{
                                                return(
                                                    <li className="text-white mb-1 text-lg hover:text-ecoad-green" key={index}>
                                                        <a href={item.link}>{item.text}</a>
                                                    </li>
                                                )
                                            }
                                        })}
                                    </ul>
                                </div>
                            )
                        })}
                </div>
                <div className="w-full text-center ">
                    <span className="text-gray-500">2024 - eco ad® - iq digital</span>
                </div>
            </div>
        </div>
    </>
    )
}

export default Sidebar;