import React from "react";
import Hamburger from "../../static/images/hamburger.svg";

const Navbar = ({ logo_ecoAD, logo_iq, onHamburgerClick, sidebarActive, padding }) => {
    let style = { background: "rgba(255,255,255,1)", filter: "drop-shadow(0px 0.1rem 4px lightgrey)" };
    return (
        <nav id="navbar" style={style} className="z-10 top-0 fixed w-full">
            <div className="max-w-screen-lg w-11/12 max-w-screen-xl h-20 m-auto flex justify-center items-center text-xl">
                <div className="w-full flex justify-center">
                    <div className="max-w-sm flex justify-center">
                        <a href="http://www.iqdigital.de">
                            <div className="h-auto flex justify-start items-center">
                                <img className="w-20 mx-2" alt="ecoAD Logo" src={logo_ecoAD}></img>
                                <span className="text-lg -mt-1">by</span>
                                <img className="h-6 mx-2" alt="iqdigital Logo" src={logo_iq}></img>
                            </div>
                        </a>
                    </div>
                    <div className="w-full"></div>
                    <div className="flex flex-row mr-16 justify-center items-center"><a href="/">de</a><span className="mr-4 ml-4">|</span><a href="/en">en</a></div>
                    <div className="max-w-xs h-auto flex justify-center items-center">
                        <img className="cursor-pointer" width="40" src={Hamburger} alt="hamburger menu" onClick={onHamburgerClick} />
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;