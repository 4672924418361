import React from "react";

const Button = ({text, link, target}) =>{
    if(!link){
        return(
            <button className="bg-ecoGreen rounded-lg font-medium text-white px-16 py-2 text-lg full-size">{text}</button>
        )
    }
    else{
        if(target){
            return(
                <a href={link} target={target}>
                    <button className="bg-ecoGreen font-medium rounded-lg text-white px-16 py-2 text-lg full-size">{text}</button>
                </a>
            )
        }
        else{
            return(
                <a href={link}>
                    <button className="bg-ecoGreen font-medium rounded-lg text-white px-16 py-2 text-lg full-size">{text}</button>
                </a>
            )
        }
    }
}

export default Button;