import React from "react";

const Footer = ({ data }) => {
    return (
        <footer className="w-full bg-ecoGreen">
            <div className="w-11/12 max-w-screen-xl m-auto">
                <div className="p-10 md:flex justify-around items-center">
                    <div className="mb-10 w-full lg:w-1/2" style={{ marginRight: "5%" }}>
                        {/* Absätze im content erkennen und in <p>-Tags rendern */}
                        {data.content.split("\n\n").map((paragraph, index) => (
                            <p key={index} className="text-white text-justify mb-4">
                                {paragraph}
                            </p>
                        ))}
                    </div>
                    <div className="flex justify-start items-start mt-5">
                        {data.lists.map((list, index) => {
                            return (
                                <div key={index} className="mx-5">
                                    <h5 className="font-bold mb-2">{list.header}</h5>
                                    <ul>
                                        {list.items.map((item, index) => {
                                            // Links sollten im neuen Tab geöffnet werden, bekommen daher das attr. target="blank"
                                            if (list.header === "Links") {
                                                return (
                                                    <li key={index} className="my-1">
                                                        <a className="underline" target="_blank" rel="noreferrer" href={item.link}>{item.text}</a>
                                                    </li>
                                                );
                                            } else {
                                                return (
                                                    <li key={index} className="my-1">
                                                        <a className="underline" href={item.link}>{item.text}</a>
                                                    </li>
                                                );
                                            }
                                        })}
                                    </ul>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="absolute bottom-0 color-ecoGreen select-none">{Buffer.from("YnkgTmlscyBLdW1tZXJ0", "base64").toString("binary")}</div>
        </footer>
    );
};

export default Footer;
