import React from "react";

import TemplateString from "./templateString";
import CarbonString from "./carbonString";
import parseAndTransform from "../helper/parseAndTransform"

const TransformTemplate = ({content, formattingClassName}) => {
    return(
        parseAndTransform(content, "%").map((obj, index) =>{
            if(obj.template){
                return(
                    <TemplateString
                        text={obj.string}
                        classname={formattingClassName}
                        key={index}
                    />
                )
            }
            else{
                return(
                    parseAndTransform(obj.string, "&").map((obj, index) => {
                        if(obj.template){
                            return(
                                <CarbonString
                                    text={obj.string}
                                    key={index}
                                />
                            )
                        }
                        else{
                            return <span key={index}>{obj.string}</span>
                        }
                    })
                )
            }
        })
    )
}

export default TransformTemplate;