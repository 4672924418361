import React from "react";

const BlurBackground = ({onClick, color, opacity, children}) => {
    if(!children){
        return(
            <div aria-hidden="true" style={{background: color, opacity: opacity}} onClick={onClick} className="fixed w-full h-full top-0 left-0 bg-white opacity-50 z-20"></div>
        )
    }
    else{
        return(
            <div style={{background: color, opacity: opacity}} className="fixed w-full h-full top-0 left-0 bg-white bg-opacity-50 z-20 flex justify-center items-center overflow-auto">
                <div aria-hidden="true" onClick={onClick} className="fixed w-full h-full top-0 left-0 z-21"></div>
                {children}
            </div>
        )
    }
}

export default BlurBackground;